








import { computed, defineComponent } from '@nuxtjs/composition-api';
import { errorToString } from '~/helpers/error/errorToString';

export default defineComponent({
  name: 'GenericFormError',
  props: {
    error: {
      type: [String, Error],
      default: '',
    },
  },
  setup(props) {
    return {
      errorMessage: computed<string>(() => {
        if (!props.error) {
          return 'Something went wrong on our side. Please try again or contact support';
        }
        return errorToString(props.error);
      }),
    };
  },
});
