



























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import {
  ValidationObserver,
} from 'vee-validate';
import { SfCheckbox } from '@storefront-ui/vue';
import type { UseUserLoginParams } from '@vue-storefront/core';
import GenericFormError from '~/components/atoms/GenericFormError.vue';
import ValidatedInput from '~/components/atoms/Inputs/ValidatedInput/ValidatedInput.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { useUserExtended } from '~/composables';

export default defineComponent({
  name: 'LoginForm',
  components: {
    ValidationObserver,
    GenericFormError,
    ValidatedInput,
    Button,
    SfCheckbox,
  },
  props: {
    caption: {
      type: String,
      default: 'Login',
    },
  },
  setup() {
    const form: Ref<UseUserLoginParams> = ref<UseUserLoginParams>({
      username: '',
      password: '',
    });
    const { loading, error, login } = useUserExtended();

    const handleLoginSubmit = (user: UseUserLoginParams) => {
      login({ userData: user });
    };

    return {
      handleLoginSubmit,
      form,
      loading,
      error,
    };
  },
});
