























import { defineComponent, watch, computed } from '@nuxtjs/composition-api';
import { useUser } from '@vsf-enterprise/commercetools';
import { useVSFContext, onSSR } from '@vue-storefront/core';
import RenderContent from '~/cms/RenderContent.vue';
import LoginForm from '~/components/organisms/LoginForm/LoginForm.vue';
import { useInternalCms, useRouteExtended } from '~/composables';
import { COMPONENTS } from '~/constants/cms';
import { ROUTES } from '~/constants/routes';

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
    RenderContent,
  },
  layout: 'no-footer-layout',
  setup(_props, { root: { $router } }) {
    const { isAuthenticated } = useUser();
    const { redirect } = useVSFContext();
    const { getAdjustedSlug } = useRouteExtended();

    const rerouteAuthenticatedUser = () => {
      if (isAuthenticated.value) {
        redirect(getAdjustedSlug(ROUTES.MY_ACCOUNT));
      }
    };

    const {
      loadComponent,
      canShowContent,
      internalCmsContent,
    } = useInternalCms(COMPONENTS.CREATE_ACCOUNT_PROMO.SLUG);

    onSSR(async () => {
      rerouteAuthenticatedUser();
      await loadComponent();
    });

    watch(isAuthenticated, () => {
      if (isAuthenticated.value) {
        $router.go(-1);
      }
    });

    const resetPasswordSlug = computed(() => getAdjustedSlug(ROUTES.RESET_PASSWORD));

    return {
      redirect,
      ROUTES,
      canShowContent,
      internalCmsContent,
      resetPasswordSlug,
    };
  },
});
